import { css } from 'styled-components';

const primaryFont = css`
  font-family: League-Spartan-Light, sans-serif;
  font-weight: 200;
  font-style: normal;
`;
const secondaryFont = css`
  font-family: CrimsonText-Regular, sans-serif;
  font-weight: 200;
  letter-spacing: 0.3;
`;

const standardFontSize = css`
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5;
`;

const smallerFontSize = css`
  font-size: 0.8rem;
`;

const hTags = css`
  ${secondaryFont}
  font-weight: 400;
  color: ${(props) => props.theme.colors.secondary.main};
`;

const h1 = css`
  font-size: 3.5rem;
  line-height: 3rem;

  @media ${(props) => props.theme.mq.sm} {
    font-size: 4rem;
    line-height: 4.5rem;
  }

  @media ${(props) => props.theme.mq.md} {
    font-size: 4rem;
    line-height: 4.5rem;
  }
`;

const h2 = css`
  font-size: 2rem;
  line-height: 2.1rem;

  @media ${(props) => props.theme.mq.sm} {
    font-size: 3rem;
    line-height: 2.5rem;
  }
`;

const h3 = css`
  font-size: 1.5rem;
  line-height: 1.5rem;

  @media ${(props) => props.theme.mq.sm} {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
`;

const h4 = css`
  font-size: 1.5rem;
  line-height: 2rem;
`;

const h5 = css`
  font-size: 1.35rem;
`;

const h6 = css`
  font-size: 1.2rem;
`;

const textIndent = css`
  text-indent: -0.625rem;
  @media ${(props) => props.theme.mq.sm} {
    text-indent: -1rem;
  }
  @media ${(props) => props.theme.mq.md} {
    text-indent: -2.5rem;
  }
`;

const fonts = {
  primaryFont,
  secondaryFont,
  standardFontSize,
  smallerFontSize,
  hTags,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  textIndent,
};

export default fonts;
