import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Script } from 'gatsby';

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />

      <Script
        id='demandIQ'
        strategy='post-hydrate'
        dangerouslySetInnerHTML={{
          __html: ` var diqKDDScript = document.createElement('script'),
                    fileName = window.location.host;
    if(fileName.indexOf('www.') === 0) {
        fileName = fileName.substring(4,fileName.length);
    }
    diqKDDScript.type = 'text/javascript';
    diqKDDScript.async = false;
    diqKDDScript.defer = true;
    diqKDDScript.src = "https://diq.keydatadashboard.com/diq/customers/" + fileName + ".js?v=" + new Date().setHours(0,0,0,0);
    document.body.appendChild(diqKDDScript);`,
        }}
      />

      <Header />
      {/* <PropertyContextProvider> */}
      {children}
      {/* </PropertyContextProvider> */}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const GlobalStyles = createGlobalStyle`

  * {
    overflow-anchor: none;
  }

  html {
      height: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
  strong{
    font-weight: 200 !important;
  }
  body {
    /* ${(props) => props.theme.fonts.primaryFont};
    ${(props) => props.theme.fonts.standardFontSize}; */
    color: ${(props) => props.theme.colors.secondary.main};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    height: 100%;   
    background-color: white;
    margin:0;
    padding:0;

    &.noScroll{
      overflow: hidden;
    }
  }

  *,
  *:before,
  *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  button:focus {
      outline: 0;
  }

  a,
  button {
      cursor: pointer;
      font-size: inherit;
  }

  *{
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
    outline: none !important;
  }

  a{
    color: ${(props) => props.theme.colors.primary.main};
    transition: all 0.3s ease-in-out;
    word-wrap: break-word;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.secondary.main};
      opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.colors.secondary.main};
  }

  ::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.secondary.main};
  }

  select::-ms-expand {
      display: none;
  }

  .gatsby-image-wrapper{
      height:100%;
  }

  @media print {
    .dontPrint{
      display:none;
    }
  }

  /* --- Fonts --- */

  /* h1 {
    ${(props) => props.theme.fonts.hTags};
    ${(props) => props.theme.fonts.h1};
  }

  h2 {
    ${(props) => props.theme.fonts.hTags};
    ${(props) => props.theme.fonts.h2};
  }

  h3 {
    ${(props) => props.theme.fonts.hTags};
    ${(props) => props.theme.fonts.h3};
  } 

  h4 {
    ${(props) => props.theme.fonts.hTags};
    ${(props) => props.theme.fonts.h4};
  }

  h5 {
    ${(props) => props.theme.fonts.hTags};
    ${(props) => props.theme.fonts.h5};
  }

  h6 {
    ${(props) => props.theme.fonts.hTags};
    ${(props) => props.theme.fonts.h6};
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  } */


`;

export default Layout;
