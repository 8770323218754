import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {
  TextField,
  Box,
  FormControl,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';
import Heading from '../../utils/heading';

export const MailChimpForm = () => {
  // Individual state variables
  const [FNAME, setFNAME] = useState(null);
  const [LNAME, setLNAME] = useState(null);
  const [EMAIL, setEMAIL] = useState(null);
  const [groups, setGroups] = useState({
    2: false,
    4: false,
    8: false,
    16: false,
    32: false,
    64: false,
  });
  const [result, setResult] = useState(null);
  const [send, setSend] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const checkedGroups = Object.fromEntries(
      Object.entries(groups)
        .filter(([_, value]) => value) // ✅ Only include checked checkboxes
        .map(([key]) => [`group[383246][${key}]`, 'true'])
    );

    const formData = {
      FNAME,
      LNAME,
      ...checkedGroups, // ✅ Only checked checkboxes get sent
    };

    const result = await addToMailchimp(EMAIL, formData);
    setResult(result);
    setSend(result.result === 'success' ? 'success' : 'error');
    setMessage(result.msg);
  };

  // Handle changes to form fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'FNAME') {
      setFNAME(value);
    }
    if (name === 'LNAME') {
      setLNAME(value);
    } else if (name === 'EMAIL') {
      setEMAIL(value);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setGroups((prevState) => {
      const updatedGroups = { ...prevState, [Number(name)]: checked };
      console.log('Updated groups state:', updatedGroups); // Debugging
      return updatedGroups;
    });
  };

  const validation = () => {
    if (send === 'success') {
      return (
        <div className='vali' dangerouslySetInnerHTML={{ __html: message }} />
      );
    } else if (send === 'error') {
      return (
        <div className='vali' dangerouslySetInnerHTML={{ __html: message }} />
      );
    } else {
      return (
        <div className='vali'>
          You are signing up to receive marketing updates from us and can opt
          out at any time. Visit our{' '}
          <a title='Privacy Policy' target='_blank' href='/privacy-policy/'>
            Privacy Policy
          </a>{' '}
          for more information.
        </div>
      );
    }
  };

  return (
    <Box
      sx={{ py: '3rem' }}
      component='form'
      onSubmit={handleSubmit}
      className='newsForm'
    >
      <div className='text'>
        <Heading type='title' as='h3' color='white'>
          Subscribe to our newsletter
        </Heading>

        <p>to keep up to date with news and offers!</p>
      </div>
      <div className='name'>
        <TextField
          id='outlined-name-input'
          label='First name'
          type='text'
          name='FNAME'
          value={FNAME}
          variant='outlined'
          fullWidth
          onChange={(e) => handleChange(e)}
          required
        />
        <TextField
          id='outlined-name-input'
          label='Last name'
          type='text'
          name='LNAME'
          value={LNAME}
          variant='outlined'
          fullWidth
          onChange={(e) => handleChange(e)}
          required
        />
      </div>

      <div className='email'>
        <TextField
          id='outlined-email-input'
          label='Email'
          type='email'
          name='EMAIL'
          value={EMAIL}
          variant='outlined'
          fullWidth
          onChange={(e) => handleChange(e)}
          required
        />
      </div>

      <FormControl
        className='interested'
        sx={{ m: 3, width: '100%', gridColumn: '1/3' }}
        component='fieldset'
        variant='standard'
      >
        <Typography
          component='legend'
          variant='h5'
          sx={{ color: 'white !important', pb: 2 }}
        >
          Which types of holidays are you interested in?
        </Typography>
        <FormGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr)',
              lg: 'repeat(6, 1fr)',
            },
          }}
        >
          {['2', '4', '8', '16', '32', '64'].map((value) => (
            <FormControlLabel
              key={value}
              color='primary.main'
              sx={{ backgroundColor: 'transparent', color: 'white' }}
              control={
                <Checkbox
                  size='small'
                  sx={{ color: 'white' }}
                  checked={groups[Number(value)] || false} // ✅ Ensure it properly binds
                  onChange={(e) => handleCheckboxChange(e)}
                  name={value}
                  id={`mce-group[383246]-383246-${value}`}
                />
              }
              label={
                value === '2'
                  ? 'Accessible Properties'
                  : value === '4'
                  ? 'Dog-friendly Properties'
                  : value === '8'
                  ? 'Family Holidays'
                  : value === '16'
                  ? 'Romantic Breaks'
                  : value === '32'
                  ? 'Rural Retreats'
                  : 'Short Stays'
              }
            />
          ))}
        </FormGroup>
      </FormControl>

      <div className='subbers'>
        <button label='Submit' type='submit'>
          Submit
        </button>
        {validation()}
      </div>
    </Box>
  );
};
